'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import Icon from '@haaretz/s-icon';
import dynamic from 'next/dynamic';
import * as React from 'react';
import s9 from 'style9';

import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const Popover = dynamic(() => import('@haaretz/s-popover').then(mod => mod.default));

function defaultPopoverContent(advertiser: string) {
  return fork({
    default: `תוכן ממומן בשיתוף ${advertiser}.
      הכתבה הופקה ונערכה על ידי המחלקה המסחרית של הארץ`,
    hdc: `Paid by ${advertiser}
    The article was produced by the commercial department of Haaretz`,
  });
}

// `c` is short for `classNames`
const c = s9.create({
  footer: {
    zIndex: zIndex('above'),
    position: 'relative',
  },
  adsDescription: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: color('promoted300'),
    outline: 'none',
  },
  icon: {
    minWidth: space(4),
    minHeight: space(4),
    marginInlineEnd: space(1),
    alignSelf: 'flex-start',
    marginBlockStart: space(0.5),
  },
  popoverContent: {
    width: space(30),
    ...merge({
      ...mq({ from: 'l', value: { width: space(45) } }),
    }),
  },
  popoverTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  adsText: {
    textAlign: 'start',
    ...typesetter(-2),

    ...merge({
      ...mq({ from: 'l', value: { ...typesetter(-1) } }),
      ...mq({ from: 'xl', value: { ...typesetter(-2) } }),
      ...mq({ from: 'xxl', value: { ...typesetter(-3) } }),
    }),
  },
});

export interface ClickTrackerFooterProps {
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
  adsTextStyleExtend?: StyleExtend;
  advertiser?: string | null;
  popoverText?: string;
}

export default function ClickTrackerFooter({
  inlineStyle,
  styleExtend = [],
  advertiser,
  popoverText,
  adsTextStyleExtend = [],
}: ClickTrackerFooterProps) {
  const adsRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenStateChangeHandler = (isOpenFromChild: boolean) => {
    setIsOpen(isOpenFromChild);
  };

  return (
    <footer className={s9(c.footer, ...styleExtend)} style={inlineStyle}>
      <div className={s9(c.adsDescription)}>
        <span
          className={s9(c.adsText, c.popoverTitle, ...adsTextStyleExtend)}
          onMouseMove={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          {advertiser ? (
            <>
              <Icon icon="warn" styleExtend={[c.icon]} ref={adsRef} />
              <span>
                {fork({ default: 'בשיתוף ', hdc: 'Paid by' })}
                {advertiser}
              </span>
            </>
          ) : (
            <span>{fork({ default: 'תוכן שיווקי', hdc: 'Promoted Content' })}</span>
          )}
        </span>
      </div>
      {advertiser ? (
        <Popover
          kind="tooltip"
          refersToRef={adsRef}
          variant="inverse"
          placement="top-start"
          onToggle={handleOpenStateChangeHandler}
          offsetValue={{ crossAxis: space(-2) }}
          isOpen={isOpen}
        >
          <div className={s9(c.popoverContent, c.adsText)}>
            {popoverText || defaultPopoverContent(advertiser)}
          </div>
        </Popover>
      ) : null}
    </footer>
  );
}
