'use client';

import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';

import type { ListFragment } from '@haaretz/s-fragments/List';

interface ExtraLinksProps {
  listTitle: string;
  link: NonNullable<ListFragment['extraLinks']>[number];
}

export default function ExtraLinkClient({ link, listTitle }: ExtraLinksProps) {
  const biAction = useBi();
  return link.url ? (
    <HtzLink
      onClick={() => {
        biAction({
          action_id: 153,
          feature: 'content list',
          campaign_name: listTitle,
          campaign_details: link.title ?? '',
        });
      }}
      href={link.url}
    >
      {link.title}
    </HtzLink>
  ) : null;
}
