'use client';

import {
  createDescendantContext,
  Descendant,
  DescendantProvider,
} from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';

export const TabPanelsDescendantContext = createDescendantContext<Descendant<HTMLDivElement>>(
  'TabPanelsDescendantContext'
);

function TabPanels({ children }: { children: React.ReactNode }) {
  const [tabPanels, setTabPanels] = React.useState<Array<Descendant<HTMLDivElement>>>([]);
  return (
    <DescendantProvider context={TabPanelsDescendantContext} items={tabPanels} set={setTabPanels}>
      {children}
    </DescendantProvider>
  );
}

export default TabPanels;
