'use client';

import React from 'react';

export const StocksTabsContext = React.createContext<string | null>(null);

export const StocksTabsContextUpdater = React.createContext<
  React.Dispatch<React.SetStateAction<string | null>>
>(() => {});

export default function StocksTabsProvider({ children }: { children: React.ReactNode }) {
  const [selectedIndex, setSelectedIndex] = React.useState<string | null>(null);

  return (
    <StocksTabsContext.Provider value={selectedIndex}>
      <StocksTabsContextUpdater.Provider value={setSelectedIndex}>
        {children}
      </StocksTabsContextUpdater.Provider>
    </StocksTabsContext.Provider>
  );
}
