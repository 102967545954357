export default function throttleFn(fn: () => void, threshold = 250) {
  let latest: number;
  let deferTimer: ReturnType<typeof setTimeout>;

  function runThrottledFn(): void {
    const now = Date.now();
    if (latest && now < latest + threshold) {
      if (deferTimer) clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        latest = now;
        fn();
      }, threshold);
    } else {
      latest = now;
      fn();
    }
  }

  return runThrottledFn;
}
