'use client';

import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import { articleIdRx, liveBlogItemIdRx } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import React from 'react';
import s9 from 'style9';

import type { BiData } from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';
import type { RelatedArticleFragment } from '@haaretz/s-fragments/RelatedArticle';
import type { StyleExtend } from '@haaretz/s-types';

export interface UpdateItemProps extends Pick<RelatedArticleFragment, 'url' | 'title'> {
  listId: ListFragment['contentId'];
  listStyle: ListFragment['listStyle'];
  listTitle: ListFragment['title'];
  isLast?: boolean;
  styleExtend?: StyleExtend;
}
const c = s9.create({
  item: {
    position: 'relative',
    display: 'flex',
    paddingBottom: space(1),
    alignItems: 'baseline',

    ':before': {
      flexShrink: 0,
      flexGrow: 0,
      zIndex: 1,
      content: '""',
      display: 'block',
      width: space(2),
      height: space(2),
      backgroundColor: `var(--liveItemBulletColor, ${color('exclusive')})`,
      borderRadius: '50%',
      marginInlineEnd: space(2),
    },

    ':after': {
      content: '""',
      position: 'absolute',
      top: '0.5lh',
      insetInlineStart: '3.5px',
      display: 'block',
      width: '1px',
      height: '100%',
      backgroundColor: `var(--liveItemLineColor, ${color('neutral500')})`,
      zIndex: 0,
    },
  },
  lastItem: {
    paddingBottom: 0,

    ':after': {
      content: 'none',
    },
  },
});

export default function UpdateItem({
  url,
  title,
  isLast,
  listId,
  listStyle,
  listTitle,
  styleExtend = [],
}: UpdateItemProps) {
  const articleId = RegExp(articleIdRx).exec(url)?.[0];
  const liveBlogId = RegExp(liveBlogItemIdRx).exec(url)?.[1];
  const ref = React.useRef<HTMLLIElement>(null);
  const biAction = useBi();
  const bidata: BiData = {
    action_id: 109,
    next_article_id: articleId ?? '',
    campaign_name: title ?? '',
    next_article_viewname: listStyle,
    next_article_list_id: listId,
    next_article_list_name: listTitle ?? '',
    additional_info: { 'liveblog item': liveBlogId ?? '' },
  };

  useImpressionObserver({ elementRef: ref, biData: bidata });
  return (
    <li className={s9(c.item, ...styleExtend, isLast && c.lastItem)} ref={ref}>
      <HtzLink href={url} onClick={() => biAction(bidata)}>
        {title}
      </HtzLink>
    </li>
  );
}
