'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';

import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  base: {
    width: '100%',
    gridColumnStart: '1',
    gridColumnEnd: '-1',
    gridRowStart: '1',
    gridRowEnd: '2',
    minHeight: space(10),
    ...typesetter(-1),
    ...merge(
      mq({
        until: 's',
        value: {
          gridColumnEnd: -1,
          marginBottom: space(1),
        },
      }),
      mq({
        from: 'm',
        value: {
          minHeight: space(12),
          ...typesetter(0),
        },
      })
    ),
  },
  channelName: {
    color: color('primary1000'),
    display: 'inline-flex',
    alignItems: 'center',
    '::after': {
      width: space(1),
      height: space(1),
      content: "''",
      marginInlineStart: space(1),
      marginInlineEnd: space(1),
      borderRadius: radius('circle'),
      backgroundColor: color('primary1000'),
    },
  },
  inline: {
    display: 'inline',
  },
});
interface TitleProps {
  styleExtend?: StyleExtend;
}

export default function Title({ styleExtend = [] }: TitleProps) {
  const { channelName, title } = useAudioPlayer();
  return (
    <div className={s9(c.base, ...styleExtend)}>
      {channelName && <div className={s9(c.channelName)}>{channelName}</div>}
      <figcaption className={s9(c.inline)}>
        <h3 className={s9(c.inline)}>{title}</h3>
      </figcaption>
    </div>
  );
}
