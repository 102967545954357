'use client';

import { articleIdRx } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import React from 'react';

import type { LiveUpdatesProps } from './LiveUpdates';
import type { BiData } from '@haaretz/s-data-structure-types';

export default function CaptionTag({
  children,
  updatesUrl,
  listId,
  listStyle,
  listTitle,
}: React.PropsWithChildren<
  Pick<LiveUpdatesProps, 'updatesUrl' | 'listId' | 'listStyle' | 'listTitle'>
>) {
  const biAction = useBi();
  if (updatesUrl) {
    const rx = RegExp(articleIdRx);
    const articleId = rx.exec(updatesUrl)?.[0];

    const bidata: BiData = {
      action_id: 109,
      next_article_id: articleId,
      campaign_name: typeof children === 'string' ? children : 'Live Updates',
      next_article_viewname: listStyle,
      next_article_list_id: listId,
      next_article_list_name: listTitle ?? '',
    };
    return (
      <HtzLink onClick={() => biAction({ ...bidata })} href={updatesUrl}>
        {children}
      </HtzLink>
    );
  }
  return children;
}
