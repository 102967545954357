'use client';

import useIntersectionObserverWithElement from '@haaretz/s-use-intersection-observer/observedElement';
import React from 'react';

type ListProviderType = { inView: boolean; obeservedElement: React.ReactNode } | undefined;

type ListProviderActionsType = {
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ListContext = React.createContext<ListProviderType>(undefined);
export const ListActionsContext = React.createContext<ListProviderActionsType | undefined>(
  undefined
);

interface ListContextProviderProps {
  pageContentId: string;
}

export default function useList() {
  const context = React.useContext(ListContext);

  if (!context) {
    throw new Error('useList must be used within a ListContext');
  }

  return context;
}

export function useListActions() {
  const context = React.useContext(ListActionsContext);

  if (!context) {
    throw new Error('useListActions must be used within a ListActionsContext');
  }

  return context;
}

export function ListContextProvider({
  children,
  pageContentId,
}: React.PropsWithChildren<ListContextProviderProps>) {
  const [inView, obeservedElement] = useIntersectionObserverWithElement();
  const [isShowing, setIsShowing] = React.useState(true);

  const listContextValue = React.useMemo(
    () => ({
      inView,
      obeservedElement,
    }),
    [inView, obeservedElement]
  );

  const listActionsValue = React.useMemo(() => ({ setIsShowing }), [setIsShowing]);

  React.useEffect(() => {
    setIsShowing(true);
  }, [pageContentId]);

  if (!isShowing) {
    return null;
  }

  return (
    <ListContext.Provider value={listContextValue}>
      <ListActionsContext.Provider value={listActionsValue}>{children}</ListActionsContext.Provider>
    </ListContext.Provider>
  );
}
