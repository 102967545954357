'use client';

import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import { usePathname } from 'next/navigation';
import { useEffect, useCallback } from 'react';

import { getSiteProducts } from '../utils';

import type { HtzUser, Site } from '@haaretz/s-types';

const targetingProviders = new Map();

export default function GptGlobalTargeting() {
  const globalTargetingFunc = useGlobalAdManagerTargetings();

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(() => {
      globalTargetingFunc(googletag.pubads());
    });
  }, [globalTargetingFunc]);

  return null;
}

export function useGlobalAdManagerTargetings() {
  const platform = usePlatform();
  const user = useUser('cookieValue');
  const isApp = platform === 'app';
  const articleId = useArticleId();
  const pathname = usePathname();

  const puAdsTagetingFunc = useCallback(
    (pubAdsServise: googletag.PubAdsService) => {
      if (!pubAdsServise) {
        return;
      }

      const pathSegments = getPathSegments(pathname);

      targetingProviders.set('react', () => ['true']);
      targetingProviders.set('react_platform', () => (isApp ? ['App'] : null));
      targetingProviders.set('UserType', () => [
        user.userType === 'paying' ? 'payer' : user.userType,
      ]);
      targetingProviders.set('htz_user_type', () => getUserTypeForSite('htz', user));
      targetingProviders.set('tm_user_type', () => getUserTypeForSite('tm', user));
      targetingProviders.set('hdc_user_type', () => getUserTypeForSite('hdc', user));
      targetingProviders.set('section', () =>
        pathSegments && pathSegments.length > 0 ? [pathSegments[0]] : null
      );
      targetingProviders.set('sub_section', () =>
        pathSegments && pathSegments.length > 1 ? [pathSegments[1]] : null
      );
      targetingProviders.set('articleId', () => (articleId ? [articleId] : null));
      targetingProviders.set('bq_campaign_id', () => localStorage.getItem('GstatCampaign'));
      targetingProviders.set('referrer', () =>
        document.referrer.includes('facebook') ? ['facebook'] : null
      );
      targetingProviders.set('user', () => user.userTargeting);

      targetingProviders.forEach((valueGenerator, key) => {
        const value = valueGenerator && valueGenerator();
        if (value) {
          pubAdsServise.setTargeting(key, value);
        }
      });
    },
    [articleId, isApp, user]
  );

  return puAdsTagetingFunc;
}

// For Web :: Calculate the user type for a site by its product
function getUserTypeForSite(site: Site, user: HtzUser) {
  if (user.userType === 'anonymous') {
    return 'anonymous';
  }

  let userType;
  const { products = [] } = user;

  const siteProducts = getSiteProducts(site);
  const userSubscribedProduct = products.find(
    prod => siteProducts.includes(prod.prodNum) && prod.status === 'SUBSCRIBED'
  );

  if (userSubscribedProduct) {
    userType = userSubscribedProduct.isTrial ? 'trial' : 'payer';
  } else {
    userType = 'registered';
  }

  return userType;
}

// Breaks the window URL to path-segments (sections)
function getPathSegments(pathname: string) {
  return pathname && pathname.split('/').filter(part => /^[^.]+$/.test(part));
}
