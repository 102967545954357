'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import HtzImage from '@haaretz/s-htz-image';
import HtzPicture from '@haaretz/s-htz-picture';
import * as React from 'react';
import s9 from 'style9';

import type { GalleryFragment } from '@haaretz/s-fragments/Gallery';
import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { Sources } from '@haaretz/s-htz-picture';
import type { SizesType } from '@haaretz/s-image-utils/buildSizesString';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';
// `c` is short for `classNames`
const c = s9.create({
  base: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto',
  },
  picture: { width: '100%' },
  image: {
    opacity: 0,
    justifySelf: 'start',
    transitionDuration: '1.25s',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out',
    gridColumnStart: 1,
    gridColumnEnd: -1,
    gridRowStart: 1,
    gridRowEnd: -1,
  },
  text: {
    opacity: 0,
    transitionDuration: '1.25s',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out',
    zIndex: zIndex('above'),
    color: 'white',
    fontWeight: 700,
    marginInlineStart: space(3),
    ...merge(
      mq({
        until: 'l',
        value: {
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 2,
          marginTop: space(3),
        },
      }),
      mq({
        from: 'l',
        value: {
          marginBottom: space(3),
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 2,
          gridRowEnd: 3,
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
  dots: {
    marginBottom: space(3),
    ...merge(
      mq({
        until: 's',
        value: {
          display: 'none',
        },
      }),
      mq({
        until: 'l',
        value: {
          alignSelf: 'end',
        },
      })
    ),
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'end',
    zIndex: zIndex('drawer'),
    color: 'white',
    marginInlineEnd: space(1),
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 2,
    gridRowEnd: 3,
  },
  showElement: {
    opacity: 1,
    zIndex: zIndex('above'),
  },
  notShowText: {
    opacity: 0,
  },
  dot: {
    borderRadius: radius('circle'),
    marginInlineEnd: space(2),
  },
  bigDot: {
    width: space(2),
    height: space(2),
    backgroundColor: color('neutral100'),
  },
  smallDot: {
    width: space(1.5),
    height: space(1.5),
    backgroundColor: color('neutral400'),
  },
});

export type GalleryProps = GalleryFragment & {
  inlineStyle?: InlineStyles;
  styleExtend?: StyleExtend;
  maximumDots?: number;
  sources?: Sources;
  aspect?: ImageAspect;
  sizes?: SizesType;
  widths?: Array<number>;
};

export default function TeaserGallery({
  inlineStyle,
  styleExtend = [],
  aspect = 'regular',
  maximumDots = 10,
  sizes,
  sources,
  widths,
  images,
}: GalleryProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;
    if (images && images.length) {
      intervalId = setInterval(() => {
        setCurrentIndex(index => (index + 1) % images.length);
      }, 5000);
    }
    return () => {
      intervalId && clearInterval(intervalId);
    };
  }, [images]);

  const maxCharacters = (maxCharactersCount: number, str: string | undefined | null) => {
    return !!str && str.length <= maxCharactersCount;
  };

  const showDots = images && images?.length >= 3 && images?.length <= maximumDots;

  return (
    <div className={s9(c.base, ...styleExtend)} style={inlineStyle}>
      {images?.map((image, index) => {
        const caption = maxCharacters(43, image.caption) && images[index].caption;

        const contentImage = widths && (
          <HtzImage
            {...image}
            styleExtend={[c.image, currentIndex === index && c.showElement]}
            imgData={image.files[0]}
            aspect={aspect}
            widths={widths}
            sizes={sizes}
          />
        );
        const contentPicture = sources && (
          <div className={s9(c.image, c.picture, currentIndex === index && c.showElement)}>
            <HtzPicture {...image} imgData={image.files[0]} sources={sources} />
          </div>
        );

        return (
          <React.Fragment key={index}>
            {contentPicture || contentImage}
            <div className={s9(c.text, currentIndex === index && c.showElement)}>{caption}</div>
          </React.Fragment>
        );
      })}
      {showDots && (
        <div className={s9(c.dots)} data-testid="dots">
          {images?.map((_, index) => (
            <div
              key={index}
              className={s9(c.dot, currentIndex === index ? c.bigDot : c.smallDot)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
