'use client';

import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import { articleIdRx } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import useBi from '@haaretz/s-use-bi';
import s9 from 'style9';

import LiveUpdatesIcon from './LiveUpdatesIcon';

import type { BiData } from '@haaretz/s-data-structure-types';
import type { ListFragment } from '@haaretz/s-fragments/List';
import type { StyleExtend } from '@haaretz/s-types';

interface LiveUpdatesLinkProps {
  href: string;
  listId: ListFragment['contentId'];
  listStyle: ListFragment['listStyle'];
  listTitle: ListFragment['title'];
  styleExtend?: StyleExtend;
  textStyleExtnd?: StyleExtend;
  iconStyleExtend?: StyleExtend;
}

const c = s9.create({
  base: {
    marginTop: space(1),
    display: 'flex',
    gap: space(2),
    alignItems: 'center',
  },
});

export default function LiveUpdatesLink({
  iconStyleExtend,
  href,
  listId,
  listStyle,
  listTitle,
  styleExtend = [],
  textStyleExtnd = [],
}: LiveUpdatesLinkProps) {
  const biAction = useBi();
  const rx = RegExp(articleIdRx);
  const articleId = rx.exec(href)?.[0];
  const bidata: BiData = {
    action_id: 109,
    next_article_id: articleId,
    campaign_name: fork({ default: 'עדכונים נוספים', hdc: 'More Updates' }),
    next_article_viewname: listStyle,
    next_article_list_id: listId,
    next_article_list_name: listTitle ?? '',
  };
  return (
    <HtzLink href={href} onClick={() => biAction(bidata)} className={s9(c.base, ...styleExtend)}>
      <span className={s9(...textStyleExtnd)}>
        {fork({ default: 'עדכונים נוספים', hdc: 'More Updates' })}
      </span>
      {articleId ? <LiveUpdatesIcon articleId={articleId} styleExtend={iconStyleExtend} /> : null}
    </HtzLink>
  );
}
