import * as Types from '@haaretz/s-fragments/Types';

import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { fetch } from '@haaretz/s-graphql-fetcher';
export type IndexAssetsTableQueryVariables = Types.Exact<{
  assetIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type IndexAssetsTableQuery = (
  { assets?: Array<(
    { id: string, name?: string | null, changePercentage: number, value: number, type: string }
    & { __typename: 'Asset' }
  )> | null }
  & { __typename: 'Query' }
);


export const IndexAssetsTableQueryDocument = `
    query IndexAssetsTableQuery($assetIds: [String!]!) {
  assets(ids: $assetIds) {
    id
    name
    changePercentage
    value
    type
  }
}
    `;
export const useIndexAssetsTableQuery = <
      TData = IndexAssetsTableQuery,
      TError = Error
    >(
      variables: IndexAssetsTableQueryVariables,
      options?: UseQueryOptions<IndexAssetsTableQuery, TError, TData>
    ) =>
    useQuery<IndexAssetsTableQuery, TError, TData>(
      ['IndexAssetsTableQuery', variables],
      fetch<IndexAssetsTableQuery, IndexAssetsTableQueryVariables>(IndexAssetsTableQueryDocument, variables),
      options
    );
useIndexAssetsTableQuery.document = IndexAssetsTableQueryDocument;


useIndexAssetsTableQuery.getKey = (variables: IndexAssetsTableQueryVariables) => ['IndexAssetsTableQuery', variables];
;

export const useInfiniteIndexAssetsTableQuery = <
      TData = IndexAssetsTableQuery,
      TError = Error
    >(
      pageParamKey: keyof IndexAssetsTableQueryVariables,
      variables: IndexAssetsTableQueryVariables,
      options?: UseInfiniteQueryOptions<IndexAssetsTableQuery, TError, TData>
    ) =>{
    
    return useInfiniteQuery<IndexAssetsTableQuery, TError, TData>(
      ['IndexAssetsTableQuery.infinite', variables],
      (metaData) => fetch<IndexAssetsTableQuery, IndexAssetsTableQueryVariables>(IndexAssetsTableQueryDocument, {...variables, ...(metaData.pageParam ?? {})})(metaData),
      options
    )};


useInfiniteIndexAssetsTableQuery.getKey = (variables: IndexAssetsTableQueryVariables) => ['IndexAssetsTableQuery.infinite', variables];
;

useIndexAssetsTableQuery.fetcher = (variables: IndexAssetsTableQueryVariables, options?: RequestInit['headers']) => fetch<IndexAssetsTableQuery, IndexAssetsTableQueryVariables>(IndexAssetsTableQueryDocument, variables, options);