'use client';
import color from '@haaretz/l-color.macro';
import typesetter from '@haaretz/l-type.macro';
import { useEffect, useState } from 'react';
import s9 from 'style9';

import fetchArticleUpdatesCount from './fetchArticleUpdatesCount';

import type { StyleExtend } from '@haaretz/s-types';

interface LiveUpdatesIconProps {
  articleId: string;
  styleExtend?: StyleExtend;
}

const c = s9.create({
  base: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1.125lh',
    aspectRatio: '1',
    backgroundColor: color('neutral1000', { opacity: 0.2 }),
    color: color('exclusive'),
    fontWeight: 700,
    transform: 'scale(0)',
    ...typesetter(-3),
  },
  popin: {
    animationIterationCount: '1',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationName: s9.keyframes({
      '70%': {
        transform: 'scale(1.2)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    }),
  },
});

export default function LiveUpdatesIcon({ articleId, styleExtend = [] }: LiveUpdatesIconProps) {
  const [newUpdatesCount, setNewUpdatesCount] = useState(0);

  useEffect(() => {
    const setUpdatesCount = async (aid: string) => {
      let count = 0;
      try {
        count = await fetchArticleUpdatesCount(aid);
      } catch (err) {
        count = 0;
      }

      if (count > 0) {
        setNewUpdatesCount(count);
      }
    };

    setUpdatesCount(articleId);
  }, [articleId]);

  return newUpdatesCount > 0 ? (
    <span className={s9(c.base, c.popin, ...styleExtend)}>{newUpdatesCount}</span>
  ) : null;
}
