'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import space from '@haaretz/l-space.macro';
import useAnimated, { useAnimatedUpdater } from '@haaretz/s-ui-providers/AnimatedProvider';
import * as React from 'react';
import s9 from 'style9';

import { TabsContext } from './providers/TabsProvider';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  tablist: {
    display: 'flex',
    minWidth: '100%',
    position: 'relative',
  },
  tablistBorder: {
    ':after': {
      content: "''",
      bottom: space(-0.75),
      position: 'absolute',
      insetInlineEnd: 0,
      insetInlineStart: 0,
      marginTop: space(-0.75),
      height: space(0.5),
      backgroundColor: color('neutral300'),
    },
  },
  tablistBoldBorder: {
    ':after': {
      content: "''",
      bottom: space(0),
      position: 'absolute',
      marginInlineStart: space(-4),
      marginInlineEnd: space(-4),
      insetInlineEnd: 0,
      insetInlineStart: 0,
      marginTop: space(-0.75),
      height: space(1),
      backgroundColor: color('neutral400'),
    },
  },
  activeIndicator: {
    backgroundColor: color('primary1000'),
    bottom: space(-0.66),
    height: space(0.75),
    insetInlineStart: 'var(--indicator-start)',
    position: 'absolute',
    transitionDuration: '300ms',
    transitionProperty: 'width,inset-inline-start',
    transitionTimingFunction: 'ease',
    width: 'var(--indicator-width)',
    zIndex: '3',
  },
  activeIndicatorBold: {
    bottom: space(-0.5),
    height: space(2),
    zIndex: '3',
  },
  hoverIndicator: {
    backgroundColor: color('neutral600'),
    bottom: space(-0.66),
    height: space(0.75),
    zIndex: '2',
    insetInlineStart: 'var(--hover-indicator-start)',
    position: 'absolute',
    transitionDuration: '300ms',
    transitionProperty: 'all',
    transitionTimingFunction: 'ease',
    width: 'var(--hover-indicator-width)',
  },
  hoverIndicatorBold: {
    bottom: space(-0.5),
    height: space(2),
  },
});

type TabListProps = {
  children: React.ReactNode;
  styleExtend?: StyleExtend;
  inlineStyle?: InlineStyles;
};

function TabList({ children, styleExtend = [], inlineStyle }: TabListProps) {
  const { activeIndicatorRect, hoverIndicatorRect, rect } = useAnimated();
  const { variant } = React.useContext(TabsContext);
  const { setRect } = useAnimatedUpdater();

  const listRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const currentRef = listRef.current;

    function updateRect() {
      if (currentRef) {
        const currentRect = currentRef.getBoundingClientRect();
        setRect(currentRect);
      }
    }
    updateRect();

    const resizeObserver = new ResizeObserver(updateRect);

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    window.addEventListener('resize', updateRect);

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      window.removeEventListener('resize', updateRect);
    };
  }, [setRect]);

  const style = {
    '--indicator-start': fork({
      default: `${activeIndicatorRect && rect ? rect.right - activeIndicatorRect.right : 0}px`, // RTL
      hdc: `${activeIndicatorRect && rect ? activeIndicatorRect.left - rect.left : 0}px`, // LTR
    }),
    '--indicator-width': `${activeIndicatorRect ? activeIndicatorRect.width : 0}px`,
  } as React.CSSProperties;
  const hoverStyle = {
    '--hover-indicator-start': fork({
      default: `${hoverIndicatorRect && rect ? rect.right - hoverIndicatorRect.right : 0}px`, // RTL
      hdc: `${hoverIndicatorRect && rect ? hoverIndicatorRect.left - rect.left : 0}px`, // LTR
    }),
    '--hover-indicator-width': `${hoverIndicatorRect ? hoverIndicatorRect.width : 0}px`,
  } as React.CSSProperties;

  return (
    <div
      ref={listRef}
      role="tablist"
      className={s9(
        c.tablist,
        variant === 'homepage' ? c.tablistBoldBorder : c.tablistBorder,
        ...styleExtend
      )}
      style={inlineStyle}
    >
      {children}
      <div
        // ref={activeIndicatorRef}
        className={s9(c.activeIndicator, variant === 'homepage' && c.activeIndicatorBold)}
        style={style}
      />
      <div
        className={s9(c.hoverIndicator, variant === 'homepage' && c.hoverIndicatorBold)}
        style={hoverStyle}
      />
    </div>
  );
}

export default TabList;
