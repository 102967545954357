'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import usePageType from '@haaretz/s-atoms/pageType';
import usePlatform from '@haaretz/s-atoms/platform';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useUser from '@haaretz/s-atoms/user';
import ErrorBoundary from '@haaretz/s-error-boundary';
import NoSSR from '@haaretz/s-no-ssr';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import articleTypeValidator from '@haaretz/s-validators/articleTypeValidator';
import Zen from '@haaretz/s-zen';
import React from 'react';
import s9 from 'style9';

import AdSlotPlaceholder from './AdSlot.placeholder';
import OutOfPageAdSlot, { OutOfPageFormat } from './components/OutOfPageAdSlot';
import StaticAdSlot from './components/StaticAdSlot';
import { inlineStyle2Obj, useRenderAdSlotPlaceholder } from './utils';

import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';
import type { AdTargetUser, HtzUser, StyleExtend } from '@haaretz/s-types';

export interface AdSlotEventHandlers {
  /* This event is fired when the creative's iframe fires its load event */
  slotOnLoad?: (slot: googletag.Slot) => void;
  /* This event is fired when an impression becomes viewable */
  slotImpressionViewable?: (slot: googletag.Slot) => void;
}

export interface AdSlotProps extends AdSlotFragment, AdSlotEventHandlers {
  styleExtend?: StyleExtend;
}

const c = s9.create({
  base: {
    gridColumnStart: 'main-start',
    gridColumnEnd: 'main-end',
    overflowX: 'hidden',
    maxWidth: '100%',
    // @ts-expect-error - we generally want to avoid these kind of things,
    //                    but  this is a special case
    ':has(> :not(.gam-placeholder):empty)': {
      display: 'none',
    },
    ':has(> .hide_)': {
      ...merge(
        mq({
          until: 's',
          value: { display: 'none' },
        })
      ),
    },
    ':has(> .hide_s)': {
      ...merge(
        mq({
          from: 's',
          until: 'm',
          value: { display: 'none' },
        })
      ),
    },
    ':has(> .hide_m)': {
      ...merge(
        mq({
          from: 'm',
          until: 'l',
          value: { display: 'none' },
        })
      ),
    },
    ':has(> .hide_l)': {
      ...merge(
        mq({
          from: 'l',
          until: 'xl',
          value: { display: 'none' },
        })
      ),
    },
  },
  ':has(> .hide_xl)': {
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: { display: 'none' },
      })
    ),
  },
  ':has(> .hide_xxl)': {
    ...merge(
      mq({
        from: 'xxl',
        value: { display: 'none' },
      })
    ),
  },
  'top-margin': {
    paddingTop: space(7),
  },
  'bottom-margin': {
    paddingBottom: space(7),
  },
  'line-start-margin': {
    paddingInlineStart: space(7),
  },
  'line-end-margin': {
    paddingInlineEnd: space(7),
  },
});

export type Styl9Classes = Exclude<keyof typeof c, 'base'>;

// test targeted user-type
function shouldTargetUser(user: HtzUser, targetUsersType: AdTargetUser): boolean {
  let shouldTarget = false;

  if (targetUsersType === 'all') {
    shouldTarget = true;
  } else {
    const userType = user.userType ?? 'anonymous';

    switch (targetUsersType) {
      case 'nonPaying':
      case 'anonymous':
      case 'registered':
        shouldTarget = ['anonymous', 'registered'].includes(userType);
        break;
      case 'paying':
      case 'digitalOnly':
      case 'digitalAndPrint':
        shouldTarget = userType === 'paying';
        break;
      default:
        shouldTarget = true;
    }
  }

  return shouldTarget;
}

function AdSlot({
  interstitial,
  targetUsers,
  inlineStyle,
  styleExtend = [],
  cssClass,
  excludePlatforms,
  excludePageTypes,
  excludeArticleStates,
  divId: serverDivId,
  ...props
}: AdSlotProps) {
  const clientDivId = React.useId();
  let divId: string | googletag.enums.OutOfPageFormat = serverDivId || clientDivId;
  const isLoaded = useLoadEvent();

  const user = useUser('cookieValue');
  const platform = usePlatform();
  const pageType = usePageType();
  const isApp = platform === 'app';
  const renderingKind = useRenderingKind();
  const isArticle = articleTypeValidator(pageType);

  const shouldExcludeFromPlatform =
    !!excludePlatforms && platform && excludePlatforms.includes(platform);

  const shouldExcludeFromPageType =
    !!excludePageTypes && pageType && excludePageTypes.includes(pageType);

  const shouldExcludeByArticleState =
    isArticle && !!excludeArticleStates && platform && excludeArticleStates.includes(renderingKind);

  const isSsr = !!props.minSizeMapping;

  const shouldDisplay =
    // if is-app then should display after useLoadEvent is resolved to true
    (!isApp || isLoaded) &&
    // if current platform is not excluded for this ad-slot
    !shouldExcludeFromPlatform &&
    // if current page-type is not excluded for this ad-slot
    !shouldExcludeFromPageType &&
    // if current-page is article and is not excluded for this as-slot
    !shouldExcludeByArticleState &&
    // and the current user-type is OK
    (isSsr || shouldTargetUser(user, targetUsers || 'all'));

  if (!props.adUnitPath) {
    console.warn(
      `An attempt to render an Ad-Slot with no 'adUnitPath' with props: ${JSON.stringify({
        interstitial,
        targetUsers,
        styleExtend,
        ...props,
      })}`
    );
    return null;
  }

  if (!shouldDisplay) {
    return null;
  }

  if (interstitial) {
    divId = OutOfPageFormat.INTERSTITIAL;
  }

  return interstitial ? (
    <OutOfPageAdSlot divId={divId} {...props} />
  ) : (
    <StaticAdSlot divId={divId} {...props} />
  );
}

function AdSlotWrapper(props: AdSlotProps) {
  const renderAsPlaceholder = useRenderAdSlotPlaceholder();
  const { styleExtend = [], inlineStyle, cssClass } = props;

  // Calculate inline style object
  const style = inlineStyle && inlineStyle2Obj(inlineStyle);

  const serverCssClasses = (cssClass?.split(' ') as Styl9Classes[])?.map(name => c[name]) || [];

  const Wrapper = props.minSizeMapping ? React.Fragment : NoSSR;

  return (
    <Wrapper>
      <Zen kind="default">
        <div
          className={`advert ${s9(c.base, ...serverCssClasses, ...styleExtend)} no-print`}
          style={style || {}}
        >
          {renderAsPlaceholder ? <AdSlotPlaceholder {...props} /> : <AdSlot {...props} />}
        </div>
      </Zen>
    </Wrapper>
  );
}

export default function AdSlotBounderyWrapper(props: AdSlotProps) {
  return (
    <ErrorBoundary>
      <AdSlotWrapper {...props} />
    </ErrorBoundary>
  );
}
