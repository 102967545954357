'use client';

import * as React from 'react';

type TabsContextValue = {
  selectedIndex: number;
  rootId: string;
  activeTabRef: HTMLButtonElement | null;
  variant: 'default' | 'homepage';
};

type TabsUpdaterContextValue = {
  setActiveTabRef: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onSelectTab: (index: number) => void;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
};

const defaultTabsContextValue: TabsContextValue = {
  selectedIndex: 0,
  rootId: '',
  activeTabRef: null,
  variant: 'default',
};

const defaultTabsUpdaterContextValue: TabsUpdaterContextValue = {
  onSelectTab: () => {},
  setSelectedIndex: () => {},
  setActiveTabRef: () => {},
};

export const TabsContext = React.createContext<TabsContextValue>(defaultTabsContextValue);
export const TabsUpdaterContext = React.createContext<TabsUpdaterContextValue>(
  defaultTabsUpdaterContextValue
);

export default function TabsProvider({
  children,
  defaultTabIndex,
  variant = 'default',
}: {
  children: React.ReactNode;
  defaultTabIndex?: number;
  variant: 'default' | 'homepage';
}) {
  const [selectedIndex, setSelectedIndex] = React.useState(defaultTabIndex ?? 0);
  const [activeTabRef, setActiveTabRef] = React.useState<HTMLButtonElement | null>(null);

  const rootId = React.useId();

  const onSelectTab = React.useCallback(
    (index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex]
  );
  return (
    <TabsContext.Provider
      value={{
        selectedIndex,
        rootId,
        activeTabRef,
        variant,
      }}
    >
      <TabsUpdaterContext.Provider
        value={{
          setSelectedIndex,
          onSelectTab,
          setActiveTabRef,
        }}
      >
        {children}
      </TabsUpdaterContext.Provider>
    </TabsContext.Provider>
  );
}
