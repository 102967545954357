'use client';

import * as React from 'react';

type Descendant<T = HTMLElement> = {
  element: T | null;
  index: number;
};

interface DescendantContextValue<DescendantType extends Descendant> {
  descendants: Array<DescendantType>;
  registerDescendant(descendant: DescendantType): () => void;
}

function DescendantProvider<DescendantType extends Descendant>({
  context: Ctx,
  children,
  items,
  set,
}: {
  context: React.Context<DescendantContextValue<DescendantType>>;
  children: React.ReactNode;
  items: DescendantType[];
  set: React.Dispatch<React.SetStateAction<Array<DescendantType>>>;
}) {
  const registerDescendant = React.useCallback(
    (descendant: DescendantType) => {
      if (descendant.element === null) return noop;

      set(cbItems => {
        const existingIndex = cbItems.findIndex(item => item.element === descendant.element);

        if (existingIndex === -1) {
          // Element not found, add new descendant
          return [...cbItems, { ...descendant, index: cbItems.length } as DescendantType];
        } else {
          // Element found, update descendant at the found index
          const updatedItems = [...cbItems];
          updatedItems[existingIndex] = { ...descendant, index: existingIndex } as DescendantType;
          return updatedItems;
        }
      });

      return () => {
        if (!descendant.element) return;
        set(cbItems => cbItems.filter(item => descendant.element !== item.element));
      };
    },
    [set]
  );

  return (
    <Ctx.Provider
      value={React.useMemo(() => {
        return {
          descendants: items,
          registerDescendant,
        };
      }, [items, registerDescendant])}
    >
      {children}
    </Ctx.Provider>
  );
}

function createDescendantContext<DescendantType extends Descendant>(name: string) {
  type T = DescendantContextValue<DescendantType>;
  const descendants: Array<DescendantType> = [];
  const ctx = React.createContext<T>({
    descendants,
    registerDescendant: () => noop,
  });
  ctx.displayName = name;
  return ctx;
}

function useDescendant<DescendantType extends Descendant>(
  element: DescendantType['element'],
  context: React.Context<DescendantContextValue<DescendantType>>
) {
  const { registerDescendant, descendants } = React.useContext(context);

  const index = descendants.findIndex(item => item.element === element);

  React.useEffect(() => {
    return registerDescendant({ element, index } as DescendantType);
  }, [element, index, registerDescendant]);

  return index;
}

function noop() {}

export type { Descendant, DescendantContextValue };
export { createDescendantContext, DescendantProvider, useDescendant };
