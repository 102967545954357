/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type {
  StyleExtend,
  InlineStyles,
  PolymorphicPropsWithoutRef,
  PolymorphicForwardRefExoticComponent,
} from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  card: {
    backgroundColor: color('contentBg'),
    borderRadius: radius('xLarge'),
    position: 'relative',
    overflow: 'hidden',
  },
  tonedVariant: {
    backgroundColor: color('neutral200'),
  },
  emphasisVariant: {
    backgroundColor: color('cardEmphasis'),
  },
  brandVariant: {
    backgroundColor: color('cardBrand'),
  },
  commercialVariant: {
    backgroundColor: color('neutral300'),
    fontFamily: fontStack('commercial'),

    ':before': {
      borderRadius: radius('xLarge'),
      bottom: 0,
      content: '""',
      height: '100%',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',

      ...border({
        color: color('neutral400'),
        side: 'all',
        spacing: 1,
        style: 'solid',
        width: '1px',
      }),
    },
  },
  outbrainVariant: {
    backgroundColor: color('cardOutbrain'),
    fontFamily: fontStack('commercial'),

    ':before': {
      borderRadius: radius('medium'),
      bottom: 0,
      content: '""',
      height: '100%',
      left: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '100%',

      ...border({
        color: color('neutral400'),
        side: 'all',
        spacing: 1,
        style: 'solid',
        width: '1px',
      }),
    },
  },
  outlinedVariant: {
    backgroundColor: 'transparent',

    ...border({
      color: color('neutral400'),
      spacing: 4,
      style: 'solid',
      width: '1px',
      side: 'all',
    }),
    ...merge({
      ...mq({
        from: 'xl',
        value: {
          ...border({
            color: color('neutral400'),
            spacing: 5,
            style: 'solid',
            width: '1px',
            side: 'all',
          }),
        },
      }),
    }),
  },

  elevation1: {
    ...shadow('low'),
  },
  elevation2: {
    ...shadow('medium'),
  },
  elevation3: {
    ...shadow('high'),
  },
});

type Variant = 'toned' | 'emphasis' | 'brand' | 'outbrain' | 'commercial' | 'outlined';

export type CardOwnProps = {
  /** The Children to be rendered inside `<Card>` */
  children?: React.ReactNode;
  /** Creates an illusion of elevating the card by placing a shadow behind it*/
  elevationLevel?: 1 | 2 | 3;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyCard(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Card
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Card styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
  /**
   * The visual appearance of the card
   */
  variant?: Variant;
};

type CardOverrideAttributes = 'variant';
type EmptyElements =
  | 'area'
  | 'base'
  | 'br'
  | 'col'
  | 'embed'
  | 'hr'
  | 'img'
  | 'input'
  | 'keygen'
  | 'link'
  | 'meta'
  | 'param'
  | 'source'
  | 'track'
  | 'wbr';

type ScriptingElements = 'script' | 'noscript' | 'template' | 'canvas';

type MetadataElements = 'base' | 'head' | 'link' | 'meta' | 'script' | 'style' | 'title';

type MediaElements = 'audio' | 'img' | 'map' | 'picture' | 'track' | 'video';

type MiscExcludedElements = 'embed' | 'iframe' | 'math' | 'object' | 'portal' | 'svg';

type ExcludedElements =
  | EmptyElements
  | ScriptingElements
  | MetadataElements
  | MediaElements
  | MiscExcludedElements;

type AllowedElements = Exclude<keyof JSX.IntrinsicElements, ExcludedElements>;

export const DEFAULT_ELEMENT = 'div';
type DefaultElement = typeof DEFAULT_ELEMENT;

export type CardProps<As extends React.ElementType = DefaultElement> = PolymorphicPropsWithoutRef<
  CardOwnProps,
  As,
  AllowedElements
>;

const Card: PolymorphicForwardRefExoticComponent<CardOwnProps, DefaultElement, AllowedElements> =
  React.forwardRef(function Card<As extends React.ElementType = AllowedElements>(
    {
      as,
      children = null,
      elevationLevel,
      inlineStyle,
      styleExtend = [],
      variant,
      ...attrs
    }: CardProps<As>,
    ref: React.ForwardedRef<As>
  ) {
    const Element: React.ElementType = as || DEFAULT_ELEMENT;
    const attrsOverride: Pick<CardOwnProps & { variant?: Variant }, CardOverrideAttributes> = {};

    const variantClass: `${Variant}Variant` | undefined = variant ? `${variant}Variant` : undefined;
    const elevationClass: `elevation${1 | 2 | 3}` | undefined = elevationLevel
      ? `elevation${elevationLevel}`
      : undefined;

    return (
      <Element
        {...attrs}
        {...attrsOverride}
        className={`${s9(
          c.card,
          variantClass && c[variantClass],
          elevationClass && c[elevationClass],
          ...styleExtend
        )} no-print`}
        style={inlineStyle}
        ref={ref}
      >
        {children}
      </Element>
    );
  });

export default Card;
