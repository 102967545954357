'use client';
import useZenMode from '@haaretz/s-atoms/zenMode';
import React from 'react';
import s9 from 'style9';

import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  animation: {
    transitionProperty: 'opacity',
    transitionDuration: '0.25s',
    transitionTimingFunction: 'ease-in-out',
  },
  fadeIn: {
    opacity: 1,
  },
  fadeOut: {
    opacity: 0,
  },
});

interface ZenProps {
  kind: 'animated' | 'default';

  styleExtend?: StyleExtend;
}

export default function Zen({
  children,
  kind,
  styleExtend = [],
}: Readonly<React.PropsWithChildren<ZenProps>>) {
  const zenMode = useZenMode();

  if (kind === 'animated') {
    return (
      <div className={s9(c.animation, zenMode ? c.fadeOut : c.fadeIn, ...styleExtend)}>
        {children}
      </div>
    );
  }

  if (zenMode) return null;

  return <>{children}</>;
}
