import config from '@haaretz/l-config';
import { articleIdRx } from '@haaretz/s-consts';

export type NewUpdatesCountResponse =
  | {
      error: string | null;
      count: never;
    }
  | {
      error: never;
      count: number;
    };

const CHECK_NEW_UPDATES_URL = `${config.get('brightspot')}/srv/personalization/new-updates`;

export default async function fetchArticleUpdatesCount(articleId: string) {
  const isValidArticleId = articleId.match(`^${articleIdRx}$`);
  let updatesCount = 0;

  if (!isValidArticleId) {
    throw new Error(`invalid article id: '${articleId}'`);
  }

  try {
    const result = await fetch(`${CHECK_NEW_UPDATES_URL}?articleId=${articleId}`, {
      credentials: 'include',
      method: 'GET',
    }).then(resp => resp.json() as Promise<NewUpdatesCountResponse>);

    updatesCount = result?.count;
  } catch (e) {
    console.error(`Cant fetch updates for id: '${articleId}'`, e);
  }

  return updatesCount;
}
