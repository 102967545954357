import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import HtzLink from '@haaretz/s-htz-link';
import s9 from 'style9';

import type { HeaderNewsListFragment } from '@haaretz/s-fragments/HeaderNewsList';
import type { PageType, RenderingKind } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  topNewsTitle: { color: color('secondary900'), display: 'inline' },
  highlighted: { color: color('primary1000') },
  topNews: {
    marginBottom: space(2),
    fontWeight: 700,
    ...typesetter(-2),
    ...merge({
      ...mq({ from: 'l', value: { ...typesetter(-1) } }),
      ...mq({ from: 'xl', value: { ...typesetter(-2) } }),
      ...mq({ from: 'xxl', value: { ...typesetter(-3) } }),
    }),
  },
  topNewsArticle: {
    gridRowStart: 2,
    gridRowEnd: 3,
    gridColumnStart: 1,
    gridColumnEnd: -1,
    ...merge(mq({ from: 'l', value: { marginBottom: space(-3) } })),
  },
  topNewsSectionTitle: {
    color: color('topNewsSection'),
  },
  topNewsSection: {
    gridColumnStart: 'main-start',
    gridColumnEnd: 'main-end',
    marginBottom: 0,
    ...border({
      color: color('neutral300'),
      style: 'solid',
      width: '1px',
      side: 'bottom',
      spacing: 2,
    }),
  },
  topNewsSectionMobile: {
    ...merge({
      ...mq({ until: 'l', value: { display: 'none' } }),
    }),
  },
  separator: {
    '::after': {
      content: '" | "',
    },
  },
  header: {
    gridRowStart: 1,
    gridRowEnd: 2,
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
});

const topNewsTitle = fork({ default: 'בראש החדשות:', hdc: 'In the News' });
interface TopNewsProps extends HeaderNewsListFragment {
  pageType?: PageType;
  renderingKind: RenderingKind;
}

export default function TopNews({
  items,
  highlightedItemsCount,
  pageType = 'Homepage',
  renderingKind,
}: TopNewsProps) {
  if (!items?.length) return null;

  return (
    <div
      className={s9(
        c.topNews,
        ...(pageType?.endsWith('Article')
          ? [c.topNewsArticle]
          : [c.topNewsSection, renderingKind !== 'expanded' && c.topNewsSectionMobile])
      )}
    >
      <h3 className={s9(c.topNewsTitle, pageType === 'Homepage' && c.topNewsSectionTitle)}>
        {topNewsTitle}{' '}
      </h3>
      {items.map((item, index, allNews) => {
        const isLast = index === allNews.length - 1;
        const isHighlited = index >= allNews.length - (highlightedItemsCount ?? 0);

        if (!item.url) return null;

        return (
          <span key={item.url} className={s9(isHighlited && c.highlighted, !isLast && c.separator)}>
            <HtzLink href={item.url}>{item.title}</HtzLink>
          </span>
        );
      })}
    </div>
  );
}
