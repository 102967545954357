'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import { useAudioPlayer } from '../hooks/useAudioPlayer';
import {
  KeyBoardButtons,
  currentTimeLabel,
  durationTimeLabel,
  formatTime,
  getProgressStyle,
  keyDirection,
  skipTime,
} from '../utils/audioPlayerUtils';

const c = s9.create({
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: space(1.5),
    flexGrow: 1,
    gridColumnStart: '1',
    gridColumnEnd: '4',
    gridRowStart: '2',
    gridRowEnd: '3',
    ...merge(
      mq({
        from: 's',
        value: {
          marginBottom: 0,
          alignItems: 'center',
          gridColumnStart: '3',
          gridColumnEnd: '4',
          gridRowStart: '2',
          gridRowEnd: '3',
        },
      })
    ),
  },
  pointerActive: {
    '::-webkit-slider-thumb': {
      boxShadow: `0 0 0 ${space(4)} ${color('primary1200', { opacity: 0.3 })}`,
      ':hover': {
        transform: 'none',
      },
    },
    '::-moz-range-thumb': {
      boxShadow: `0 0 0 ${space(4)} ${color('primary1200', { opacity: 0.3 })}`,
      ':hover': {
        transform: 'none',
      },
    },
  },

  input: {
    '::-webkit-slider-thumb': {
      WebkitAppearance: 'none',
      backgroundColor: color('primary1000'),
      width: space(4),
      height: space(4),
      borderRadius: radius('circle'),
      ':hover': {
        transition: 'all 0.4s',
        transform: 'scale(1.4)',
      },
    },
    '::-moz-range-thumb': {
      WebkitAppearance: 'none',
      border: 'none',
      backgroundColor: color('primary1000'),
      width: space(4),
      height: space(4),
      borderRadius: radius('circle'),
      ':hover': {
        transition: 'all 0.4s',
        transform: 'scale(1.4)',
      },
    },
    WebkitAppearance: 'none',
    width: '100%',
    backgroundColor: color('neutral500'),
    borderRadius: radius('small'),
    height: space(1),
    outline: 'none',
    cursor: 'pointer',
  },
  inputSchoonhoven: {
    height: space(1.5),
    backgroundColor: color('neutral400'),
  },
  time: {
    ...typesetter(-1),
    ...merge(
      mq({
        from: 's',
        value: {
          marginTop: space(-1.5),
        },
      }),
      mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          marginTop: space(-4.5),
        },
      })
    ),
  },
  currentTime: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'flex',
          // The progress bar is left-to-right in both RTL and LTR, so we need to manually handle this
          justifyContent: fork({ default: 'flex-end', hdc: 'flex-start' }),
          gridColumnStart: '3',
          gridColumnEnd: '4',
          gridRowStart: '3',
          gridRowEnd: '4',
        },
      })
    ),
  },
  outputTime: {
    ...merge(
      mq({
        from: 's',
        value: {
          gridColumnStart: 1,
          gridColumnEnd: 2,
        },
      })
    ),
  },
  inputWrapperSchoonhoven: {
    ...merge(
      mq({
        from: 's',
        value: {
          gridColumnStart: 1,
          gridColumnEnd: 2,
        },
      })
    ),
  },
  durationTime: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          display: 'flex',
          // The progress bar is left-to-right in both RTL and LTR, so we need to manually handle this
          justifyContent: fork({ default: 'flex-start', hdc: 'flex-end' }),
          gridColumnStart: '3',
          gridColumnEnd: '4',
          gridRowStart: '3',
          gridRowEnd: '4',
        },
      })
    ),
  },
});

interface SliderAudioProps {
  variant?: 'default' | 'schoonhoven';
}

export function SliderAudio({ variant = 'default' }: SliderAudioProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { currentTime, durationTime, audio, onSkip } = useAudioPlayer();

  const [isScrubingMode, setIsScrubingMode] = React.useState<boolean>(false);
  const [timerPosition, setTimerPosition] = React.useState<number>(currentTime);

  React.useEffect(() => {
    if (isScrubingMode) return;
    setTimerPosition(currentTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (inputRef && inputRef.current) {
        inputRef.current.style.background = getProgressStyle(+e.target.value, durationTime);
        setTimerPosition(+e.target.value);
      }
    },
    [durationTime]
  );

  const onMouseDown = React.useCallback(() => {
    if (!audio) return;
    setIsScrubingMode(true);
    const handleMouseUp = () => {
      setIsScrubingMode(false);
      if (inputRef.current && inputRef.current.value) {
        audio.currentTime = +inputRef.current?.value;
      }
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
  }, [audio]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (keyDirection[e.key as KeyBoardButtons]) {
        onSkip(skipTime * keyDirection[e.key as KeyBoardButtons]);
      }
    },
    [onSkip]
  );

  const isListVariant = variant === 'schoonhoven';

  return (
    <>
      <div className={s9(c.inputWrapper, isListVariant && c.inputWrapperSchoonhoven)}>
        <input
          id="audio"
          dir="ltr"
          ref={inputRef}
          type="range"
          value={timerPosition}
          className={s9(
            c.input,
            isScrubingMode && c.pointerActive,
            isListVariant && c.inputSchoonhoven
          )}
          max={durationTime}
          min={0}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onMouseDown={onMouseDown}
          onTouchStart={onMouseDown}
          style={{
            background: getProgressStyle(timerPosition, durationTime, isListVariant),
          }}
        />
      </div>
      <span className={s9(c.time, c.durationTime, isListVariant && c.outputTime)}>
        <VisuallyHidden>{durationTimeLabel}</VisuallyHidden>
        {formatTime(durationTime)}
      </span>
      <output htmlFor="audio" className={s9(c.time, c.currentTime, isListVariant && c.outputTime)}>
        <VisuallyHidden>{currentTimeLabel}</VisuallyHidden>
        {formatTime(currentTime)}
      </output>
    </>
  );
}
