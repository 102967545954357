'use client';

import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import s9 from 'style9';

import type { ErrorBoundaryPropsWithComponent } from 'react-error-boundary';

const c = s9.create({
  errorBoundary: {
    border: '1px solid #e0e0e0',
    padding: '1rem',
    margin: '1rem',
    color: 'red',
  },
});

export const logError: NonNullable<ErrorBoundaryPropsWithComponent['onError']> = (error, info) => {
  console.error('ErrorBoundary caught an error:', error, info);
};

export function Fallback({ error }: { error: Error }) {
  const [shouldShowingErrorDetails, setShouldShowingErrorDetails] = React.useState(false);

  React.useEffect(() => {
    setShouldShowingErrorDetails(/^(?:local|react-cu-).+/.test(document.location.host));
  }, []);

  if (!shouldShowingErrorDetails) return null;

  return (
    <div role="alert">
      <p className={s9(c.errorBoundary)}>{error.message}</p>
    </div>
  );
}

export default function ErrorBoundaryWrapper({ children }: React.PropsWithChildren) {
  return (
    <ErrorBoundary onError={logError} FallbackComponent={Fallback}>
      {children}
    </ErrorBoundary>
  );
}
