'use client';
import usePageType from '@haaretz/s-atoms/pageType';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import React from 'react';

import TopNews from './TopNews.hybrid';
import loadTopNews from './TopNews.loader';

import type { HeaderNewsListFragment } from '@haaretz/s-fragments/HeaderNewsList';

interface TopNewsProps {
  topNews?: HeaderNewsListFragment;
}

export default function TopNewsClient({ topNews }: TopNewsProps) {
  const [item, setItem] = React.useState<JSX.Element | null>(null);
  const [, startTransition] = React.useTransition();
  const renderingKind = useRenderingKind();
  const pageType = usePageType();

  const hasPageTopNews = !!topNews?.items?.length;

  React.useEffect(() => {
    if (!hasPageTopNews && !item) {
      startTransition(async () => {
        try {
          const response = await loadTopNews({ postCacheParams: 'sa-topnews', pageType });

          setItem(response);
        } catch (error) {
          console.error(error);
        }
      });
    }
  }, [hasPageTopNews, pageType, item]);

  return hasPageTopNews ? (
    <TopNews {...topNews} pageType={pageType} renderingKind={renderingKind} />
  ) : (
    item
  );
}
