'use client';

import dynamic from 'next/dynamic';
import React from 'react';

const SearchFoodForm = dynamic(() =>
  import('@haaretz/s-search/SearchFoodForm').then(mod => mod.default)
);

export default function SearchFoodFormWrapper() {
  return <SearchFoodForm placeOn="list" />;
}
