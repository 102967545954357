'use client';

import dynamic from 'next/dynamic';
import React from 'react';

import { StocksTabsContext } from './StocksTabsProvider';

const LineGraph = dynamic(() => import('@haaretz/s-stock-market-graph').then(mod => mod.LineGraph));

export default function Graph() {
  const selectedGraphAssetId = React.useContext(StocksTabsContext);

  return <LineGraph assetId={selectedGraphAssetId ?? ''} homepage />;
}
