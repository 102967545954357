import React from 'react';
import s9 from 'style9';

import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  observedElement: {
    width: '1px',
    height: '1px',
    position: 'absolute',
    pointerEvents: 'none',
    userSelect: 'none',
    touchAction: 'none',
  },
});

const LIST_IN_VIEW_EVENT = 'list-in-view';

const observer =
  typeof IntersectionObserver === 'function'
    ? new IntersectionObserver(onEntry, {
        rootMargin: '1000px 0px 1000px 0px',
        threshold: 0,
      })
    : null;

function onEntry(entries: IntersectionObserverEntry[]) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const event = new CustomEvent(LIST_IN_VIEW_EVENT);

      entry.target.dispatchEvent(event);

      //  NOTE: Stop observing the element once it's in view
      observer?.unobserve(entry.target);
    }
  });
}

interface UseIntersectionObserverWithElementProps {
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <ListLayout styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

export default function useIntersectionObserverWithElement(
  props?: UseIntersectionObserverWithElementProps
) {
  const { styleExtend } = props || {};

  const placeholderRef = React.useRef<HTMLDivElement | null>(null);
  const [inView, setInView] = React.useState(false);

  React.useEffect(() => {
    const placeholder = placeholderRef.current;

    const handleListInView = () => {
      setInView(true);
    };

    placeholder?.addEventListener(LIST_IN_VIEW_EVENT, handleListInView);

    return () => {
      placeholder?.removeEventListener(LIST_IN_VIEW_EVENT, handleListInView);
    };
  }, []);

  React.useEffect(() => {
    const elem = placeholderRef.current;

    if (elem && observer) observer.observe(elem);

    return () => {
      if (elem && observer) observer.unobserve(elem);
    };
  }, [placeholderRef]);
  const obeservedElement = (
    <div
      aria-hidden="true"
      className={s9(c.observedElement, ...(styleExtend || []))}
      ref={placeholderRef}
    />
  );

  return [inView, obeservedElement] as const;
}
