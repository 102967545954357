'use client';

import { AnimatedProvider } from '@haaretz/s-ui-providers/AnimatedProvider';
import {
  DescendantProvider,
  createDescendantContext,
  type Descendant,
} from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';

import TabsProvider from './components/providers/TabsProvider';

/* TabsProps accepts an array of HTML button elements with the role of `tab`, and, optionally,
the index of the tab selected by default */
type TabsProps = {
  children: React.ReactNode;
  defaultSelectedTab?: number;
  variant?: 'homepage' | 'default';
};

/* We create TabsDescendantContext to keep track of the created tabs.
   To do so, we register every tab inside the Tab component, adding it to the descendants array.
   Each tab gets index based on it's position in the DOM,
   which, in turn, is based on the order of the declaration of the tabs
*/
export const TabsDescendantContext =
  createDescendantContext<Descendant<HTMLButtonElement>>('TabsDescendantContext');

export default function Tabs({
  children,
  defaultSelectedTab: defaultTabIndex,
  variant = 'default',
}: TabsProps) {
  const [tabs, setTabs] = React.useState<Array<Descendant<HTMLButtonElement>>>([]);

  return (
    <DescendantProvider context={TabsDescendantContext} items={tabs} set={setTabs}>
      <TabsProvider defaultTabIndex={defaultTabIndex} variant={variant}>
        <AnimatedProvider>{children}</AnimatedProvider>
      </TabsProvider>
    </DescendantProvider>
  );
}

export function makeId(...args: Array<string | number | null | undefined>) {
  return args.filter(val => val != null).join('--');
}
