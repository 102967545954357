'use client';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { AnimatedContextUpdater } from '@haaretz/s-ui-providers/AnimatedProvider';
import { Descendant, useDescendant } from '@haaretz/s-ui-providers/DescendantsProvider';
import * as React from 'react';
import s9 from 'style9';

import { makeId, TabsDescendantContext } from '../Tabs';

import { TabsContext, TabsUpdaterContext } from './providers/TabsProvider';

import type { InlineStyles, StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  tab: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'inline-flex',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
    minWidth: 0,
    paddingTop: space(1),
    ...typesetter(-1),

    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          ...typesetter(1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(0),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-1),
        },
      })
    ),

    ':disabled': {
      pointerEvents: 'none',
      opacity: 0.25,
    },
  },
  tabHomepage: {
    height: space(10),
    paddingBottom: space(2),
    ...merge(
      mq({
        from: 's',
        value: {
          height: space(12),
        },
      })
    ),
  },
  tabActive: {
    color: color('primary1000'),
    fontWeight: '700',
  },
});

type TabProps = {
  children: React.ReactNode;
  disabled?: boolean;
  styleExtend?: StyleExtend;
  inlineStyle?: InlineStyles;
  onClick?: () => void;
};

export default function Tab({
  children,
  disabled,
  styleExtend = [],
  inlineStyle,
  onClick,
}: TabProps) {
  const { selectedIndex, rootId, activeTabRef, variant } = React.useContext(TabsContext);
  const { onSelectTab, setActiveTabRef } = React.useContext(TabsUpdaterContext);
  const { setActiveIndicatorRect, setHoverIndicatorRect } =
    React.useContext(AnimatedContextUpdater);
  const { descendants } = React.useContext(TabsDescendantContext);

  const tabRef = React.useRef<HTMLButtonElement>(null);

  const index = useDescendant(tabRef.current, TabsDescendantContext);

  const tabPanelId = makeId(rootId, 'tabpanel', index);
  const isSelected = index === selectedIndex;

  function onSelect() {
    onClick && onClick();
    onSelectTab(index);
  }

  function handleMouseEnter() {
    if (tabRef.current) {
      setHoverIndicatorRect(tabRef.current.getBoundingClientRect());
    }
  }

  function handleMouseLeave() {
    if (activeTabRef) {
      // setActiveIndicatorRect(activeTabRef.getBoundingClientRect());
      setHoverIndicatorRect(activeTabRef.getBoundingClientRect());
    }
  }

  function onKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      const offset =
        event.key === fork<'ArrowLeft' | 'ArrowRight'>({ default: 'ArrowLeft', hdc: 'ArrowRight' })
          ? 1
          : -1;
      const nextIndexToFocus = calculateNextIndexToFocus(descendants, index, offset);

      descendants[nextIndexToFocus]?.element?.focus();
      onSelectTab(nextIndexToFocus);
    }
  }

  React.useEffect(() => {
    const currentTabRef = tabRef.current;

    if (isSelected && currentTabRef) {
      setActiveTabRef(currentTabRef);
      setActiveIndicatorRect(currentTabRef.getBoundingClientRect());
    }

    function updateIndicatorRect() {
      if (isSelected && currentTabRef) {
        setActiveIndicatorRect(currentTabRef.getBoundingClientRect());
        setHoverIndicatorRect(currentTabRef.getBoundingClientRect());
      }
    }

    const resizeObserverTab = new ResizeObserver(updateIndicatorRect);

    if (currentTabRef) {
      resizeObserverTab.observe(currentTabRef);
    }

    window.addEventListener('resize', updateIndicatorRect);

    return () => {
      if (currentTabRef) {
        resizeObserverTab.unobserve(currentTabRef);
      }
      window.removeEventListener('resize', updateIndicatorRect);
    };
  }, [isSelected, setActiveTabRef, setActiveIndicatorRect, setHoverIndicatorRect]);

  return (
    <button
      className={s9(
        c.tab,
        variant === 'homepage' && c.tabHomepage,
        isSelected && c.tabActive,
        ...styleExtend
      )}
      style={inlineStyle}
      ref={tabRef}
      disabled={disabled}
      aria-controls={tabPanelId}
      aria-selected={isSelected}
      aria-disabled={disabled}
      role="tab"
      tabIndex={disabled || !isSelected ? -1 : undefined}
      onClick={onSelect}
      onKeyDown={onKeyDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </button>
  );
}

function calculateNextIndexToFocus(
  descendants: Array<Descendant<HTMLButtonElement>>,
  currentIndex: number,
  offset: number
) {
  let index =
    (fork({
      default: currentIndex + offset,
      hdc: currentIndex - offset + descendants.length,
    }) +
      descendants.length) %
    descendants.length;

  while (descendants[index]?.element?.disabled) {
    index =
      (fork({
        default: index + offset,
        hdc: index - offset + descendants.length,
      }) +
        descendants.length) %
      descendants.length;
  }

  return index;
}
