'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import { FINANCE_BASE_PATH } from '@haaretz/s-consts';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import { useIndexAssetsTableQuery } from '@haaretz/s-queries/AssetsListClient';
import React from 'react';
import s9 from 'style9';

import { StocksTabsContext } from './StocksTabsProvider';

const c = s9.create({
  wrapper: {
    display: 'none',
    ...merge(
      mq({
        from: 's',
        value: {
          gridColumnStart: '1',
          gridColumnEnd: '-1',
          height: space(7),
          paddingTop: space(2),
          display: 'flex',
          justifyContent: 'end',
          color: color('primary1000'),
          fontWeight: '700',
        },
      })
    ),
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    color: color('primary1000'),
    columnGap: space(1),
    fontWeight: '700',

    ...typesetter(-1),
    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  icon: {
    marginTop: '1px',
    width: space(4),
    transform: 'rotate(180deg)',
    ...typesetter(0),
    ...merge(
      mq({
        from: 'xl',
        value: {
          ...typesetter(-2),
        },
      })
    ),
  },
});

export default function MoreButton({ assetIds }: { assetIds: string[] }) {
  const { data } = useIndexAssetsTableQuery({ assetIds });
  const selectedGraphAsset = React.useContext(StocksTabsContext);

  const element = data?.assets?.find(asset => asset.id === selectedGraphAsset);

  return (
    <div className={s9(c.wrapper)}>
      {element?.name ? (
        <HtzLink
          href={`${FINANCE_BASE_PATH}/${element.type}/${element.id}`}
          className={s9(c.caption)}
        >
          למידע נוסף על {element?.name}
          <Icon icon="chevron" styleExtend={[c.icon]} />
        </HtzLink>
      ) : null}
    </div>
  );
}
