'use client';

import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import ControlPanel from '@haaretz/s-audio-player/components/AudioControls';
import FirstPlay from '@haaretz/s-audio-player/components/FirstPlay';
import { SliderAudio } from '@haaretz/s-audio-player/components/SliderAudio';
import AudioPlayerTitle from '@haaretz/s-audio-player/components/Title';
import { useAudioPlayer } from '@haaretz/s-audio-player/hooks/useAudioPlayer';
import { playLabel } from '@haaretz/s-audio-player/utils/audioPlayerUtils';
import { TeaserDataFragment } from '@haaretz/s-fragments/TeaserData';
import HtzLink from '@haaretz/s-htz-link';
import Icon from '@haaretz/s-icon';
import useBi from '@haaretz/s-use-bi';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import * as React from 'react';
import s9 from 'style9';

import type { BiDataOverrides, TeaserBiActionType } from '@haaretz/s-data-structure-types';

// `c` is short for `classNames`
const c = s9.create({
  grid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr 1fr',
    gridTemplateColumns: '1fr repeat(3, auto);',
    columnGap: space(4),
    paddingTop: space(4),
    paddingLeft: space(3),
    paddingRight: space(3),
    paddingBottom: space(4),
    ...merge(
      mq({
        from: 's',
        value: {
          gridTemplateRows: '1fr auto auto',
          gridTemplateColumns: '1fr repeat(3, auto);',
          gridColumnStart: 2,
          padding: 0,
        },
      }),
      mq({
        from: 'l',
        value: {
          columnGap: space(8),
        },
      })
    ),
  },
  iconRunButton: {
    minWidth: space(16),
    minHeight: space(16),
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'end',
    justifyContent: 'center',
    borderRadius: radius('circle'),
    backgroundColor: color('primary1000'),

    ':after': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    marginInlineStart: space(3),
    ...merge(
      mq({
        from: 'm',
        value: {
          marginInlineStart: space(5),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          minWidth: space(18),
          minHeight: space(18),
        },
      })
    ),
  },
  audioplayerTitle: {
    ...typesetter(0),
  },
  iconFirstPlay: {
    transform: 'translateX(10%)',
    width: space(8),
    height: space(8),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          width: space(9),
          height: space(9),
        },
      })
    ),
  },
  titleWrapper: {
    ...merge(
      mq({
        until: 's',
        value: {
          gridColumnStart: 1,
          gridColumnEnd: -1,
        },
      }),
      mq({
        from: 's',
        value: {
          minHeight: space(7),
        },
      }),
      mq({
        from: 'l',
        value: {
          gridColumnEnd: 2,
          minHeight: space(6),
        },
      })
    ),
  },
});

interface AudioPlayerControlPanelProps {
  biData: TeaserBiActionType;
  url: TeaserDataFragment['url'];
}
export default function AudioPlayerControlPanel({ biData, url }: AudioPlayerControlPanelProps) {
  const { wasNotPlayed, onPlayButtonClick, channelName, title } = useAudioPlayer();
  const platform = usePlatform();
  const ref = React.useRef<HTMLAnchorElement>(null);
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const biAction = useBi();

  const biDataAction: BiDataOverrides = {
    writer_name: channelName ?? '',
    action_id: 109,
    page_type: 'Homepage',
    feature: 'podcast player',
    feature_type: 'content',
    campaign_details: 'play',
  };
  useImpressionObserver({
    biData,
    elementRef: wasNotPlayed && platform !== 'desktop' ? btnRef : ref,
  });

  const onPlayButtonClickWithBi = () => {
    onPlayButtonClick();
    biAction(biDataAction);
  };
  if (!url) return null;

  return wasNotPlayed && platform === 'mobile' ? (
    <FirstPlay channelName={channelName} title={title} variant="schoonhoven">
      <button className={s9(c.iconRunButton)} ref={btnRef} onClick={onPlayButtonClickWithBi}>
        <Icon
          icon="play"
          variant="inverse"
          styleExtend={[c.iconFirstPlay]}
          screenReaderText={playLabel}
        />
      </button>
    </FirstPlay>
  ) : (
    <div className={s9(c.grid)} data-testid="audio-player-controls-schoonhoven">
      <HtzLink ref={ref} href={url} onClick={() => biAction(biData)} className={s9(c.titleWrapper)}>
        <AudioPlayerTitle styleExtend={[c.audioplayerTitle]} />
      </HtzLink>
      <SliderAudio variant="schoonhoven" />
      <ControlPanel variant="schoonhoven" />
    </div>
  );
}
