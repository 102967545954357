'use client';

import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import { hostname } from '@haaretz/s-consts';
import ErrorBoundary from '@haaretz/s-error-boundary';
import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';
import * as React from 'react';
import s9 from 'style9';

import type { OutbrainFragment } from '@haaretz/s-fragments/Outbrain';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    overflow: 'hidden',
    clear: 'both',
    gridColumnStart: 'main-start',
    ...merge(
      mq({
        from: 'l',
        value: {
          gridColumnStart: 'marginalia-start',
          gridColumnEnd: 'marginalia-end',
        },
      }),
      mq({
        from: 'xl',
        value: {
          gridColumnEnd: 'main-end',
        },
      })
    ),
  },
  sectionLayout: {
    ...merge(
      mq({
        from: 'l',
        value: {
          gridColumnStart: 'main-start',
          gridColumnEnd: 'main-end',
        },
      })
    ),
  },
});

type AppInfo = {
  deviceId: string;
  appVersion: string;
  appOS: 'android' | 'iOS';
} | null;

// TODO: It's a temporary solution. The logic will be implemented on server side
const mobileOnlyWidgetIds = ['MB_20', 'MB_4', 'MB_5', 'MB_12'];

function Outbrain(props: OutbrainFragment) {
  const { androidWidgetId, iphoneWidgetId, webWidgetId } = props;

  const [shouldRender, setShouldRender] = React.useState(false);
  const platform = usePlatform();
  const user = useUser('cookieValue');
  const articleId = useArticleId();
  const searchParams = useSearchParams();
  const isSingleComment = !!searchParams.get('commentId') || !!searchParams.get('subcommentId');
  const isInitialRenderRef = React.useRef(true);

  const [appInfo, setAppInfo] = React.useState<AppInfo>(null);

  const isLoaded = useLoadEvent(() => {
    if (window.deviceId && window.appVersion) {
      setAppInfo({
        deviceId: window.deviceId,
        appVersion: window.appVersion,
        appOS: window.navigator.userAgent.toLowerCase().includes('android') ? 'android' : 'iOS',
      });
    }
  });

  const isDesktop = platform === 'desktop';
  const isUniversity = user.university === true;

  const shouldRenderOnApp = platform !== 'app' || isLoaded;
  const shouldRenderByWidgetId = // Not appear MB_20 on desktop
    (!isDesktop || (webWidgetId && !mobileOnlyWidgetIds.includes(webWidgetId))) &&
    // Not appear AR_16 and AR_19 for universities
    (!isUniversity || (webWidgetId !== 'AR_16' && webWidgetId !== 'AR_19')) &&
    !isSingleComment;

  /* istanbul ignore next */
  const researchWidget = React.useCallback(() => {
    // NOTE: called on navigation between articles

    if (typeof window.OBR !== 'undefined') {
      setTimeout(() => {
        window.OBR?.extern?.researchWidget?.();
      }, 0);
    }
  }, []);

  React.useEffect(() => {
    if (shouldRenderOnApp && !!shouldRenderByWidgetId) {
      setShouldRender(true);

      if (!isInitialRenderRef.current) {
        researchWidget();
      } else {
        isInitialRenderRef.current = false;
      }
    } else {
      setShouldRender(false);
    }
  }, [researchWidget, shouldRender, shouldRenderByWidgetId, shouldRenderOnApp]);

  React.useEffect(() => {
    if (shouldRender) {
      const isPayingUser = user.userType === 'paying';

      if (isPayingUser && window.OB_ContextKeyValue !== 'subscribed')
        window.OB_ContextKeyValue = 'subscribed';
      else if (window.OB_ContextKeyValue) window.OB_ContextKeyValue = undefined;
    }

    return () => {
      window.OB_ContextKeyValue = undefined;
    };
  }, [shouldRender, user.userType]);

  usePathChanged(researchWidget);

  if (!shouldRender) return null;

  const dataWidgetId = getWidgetId({
    appInfo,
    androidWidgetId,
    iphoneWidgetId,
    webWidgetId,
  });

  return (
    <>
      <Script
        id="outbrain"
        strategy="afterInteractive"
        src="https://widgets.outbrain.com/outbrain.js"
        onLoad={() => {
          if (typeof window.OBR !== 'undefined') {
            window.OBR?.extern?.researchWidget?.();
          }
        }}
      />
      <section className={`OB-REACT-WRAPPER ${s9(c.base, !articleId && c.sectionLayout)} no-print`}>
        <div
          className="OUTBRAIN"
          data-src={`https://www.${hostname}/${articleId}`}
          data-ob-template="haaretz-heb"
          data-widget-id={dataWidgetId}
          data-test="outbrain"
          {...(appInfo
            ? {
                'data-ob-user-id': appInfo.deviceId,
                'data-ob-app-ver': appInfo.appVersion,
              }
            : {})}
        />
      </section>
    </>
  );
}
function getWidgetId({
  appInfo,
  androidWidgetId,
  iphoneWidgetId,
  webWidgetId,
}: {
  appInfo: AppInfo;
} & Pick<OutbrainFragment, 'androidWidgetId' | 'iphoneWidgetId' | 'webWidgetId'>) {
  let selectedWidgetId: string | null | undefined;

  if (appInfo) {
    return appInfo.appOS === 'android' ? androidWidgetId : iphoneWidgetId;
  }

  return selectedWidgetId ?? webWidgetId;
}

export default function OutbrainErrorBoundaryWrapper(props: OutbrainFragment) {
  return (
    <ErrorBoundary>
      <Outbrain {...props} />
    </ErrorBoundary>
  );
}
