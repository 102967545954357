'use client';

import useUser from '@haaretz/s-atoms/user';
import NoSSR from '@haaretz/s-no-ssr';
import React from 'react';

import type { ListFragment } from '@haaretz/s-fragments/List';

interface ExcludeUserTypeProps extends Pick<ListFragment, 'excludeUserTypes'> {}

function ExcludeUseTypeClient({
  children,
  excludeUserTypes,
}: React.PropsWithChildren<ExcludeUserTypeProps>) {
  const user = useUser('cookieValue');

  const userType = user.userType === 'paying' ? 'payer' : user.userType;

  if (!excludeUserTypes?.length) {
    return children;
  }

  if (excludeUserTypes.includes(userType)) {
    return null;
  }

  return children;
}

export default function ExcludeUserType({
  children,
  ...props
}: React.PropsWithChildren<ExcludeUserTypeProps>) {
  return (
    <NoSSR>
      <ExcludeUseTypeClient {...props}>{children}</ExcludeUseTypeClient>
    </NoSSR>
  );
}
