'use client';

import usePlatform from '@haaretz/s-atoms/platform';
import React from 'react';

import type { EnhancementFragment } from '@haaretz/s-fragments/Enhancement';

interface ExcludePlatformServerProps extends Pick<EnhancementFragment, 'excludePlatform'> {}

export default function ExcludePlatformClient({
  children,
  excludePlatform,
}: React.PropsWithChildren<ExcludePlatformServerProps>) {
  const platform = usePlatform();

  if (!excludePlatform?.length) {
    return children;
  }

  if (excludePlatform.includes(platform)) {
    return null;
  }

  return children;
}
