'use client';

import ErrorBoundary from '@haaretz/s-error-boundary';
import useOneTime from '@haaretz/s-use-once';
import * as React from 'react';

import AdSlot from '..';

import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';
import type { AdSlotPrioritySelectorFragment } from '@haaretz/s-fragments/AdSlotPrioritySelector';

export interface AdSlotPrioritySelectorProps extends AdSlotPrioritySelectorFragment {}

const GAM_SESSION_KEY = 'GAM_session'; // sessionStorage key to identify new sessions
export const GAM_OUTOFPAGE_KEY = 'GAM_OutOfPage'; // localStorage key with last display time of an out-of-page ad-slot
const ONE_HOUR = 3600000; // millis
const HALF_MINUTE = 30000; // millis
const TWENTY_SECONDS = 20000; // millis

// The priority level of each display-frequency
const DISPLAY_FREQUENCY_PRIORITY = {
  everyNewSession: 0,
  onceAnHour: 1,
  whenPossible: 2,
  none: 3,
} as const;

// a mapping of display-frequency value to a function that implements the logic
const DISPLAY_FREQUENCY_TEST = {
  everyNewSession: testGAMNewSession,
  onceAnHour: testOnceAnHour,
  whenPossible: testWhenPossible,
  none: testWhenPossible,
} as const;

function AdSlotPrioritySelector({ adSlots }: AdSlotPrioritySelectorProps) {
  const [selectedAdSlots, setSelectedAdSlots] = React.useState<AdSlotFragment[] | undefined | null>(
    []
  );
  const sortedAdSlots = React.useMemo(
    () => [...(adSlots || [])].sort(displayFrequencyComperator),
    [adSlots]
  );

  useOneTime(() => {
    const selected = selectAdSlots(sortedAdSlots);
    setSelectedAdSlots(selected);
  }, true);

  return selectedAdSlots && selectedAdSlots.length > 0
    ? selectedAdSlots.map(adSlot => <AdSlot key={adSlot.contentId} {...adSlot} />)
    : null;
}

function selectAdSlots(adSlots: AdSlotFragment[]) {
  const selectedAdSlots = [];

  for (const adSlot of adSlots) {
    const df = adSlot.displayFrequency || 'none';
    const shouldRender = DISPLAY_FREQUENCY_TEST[df](adSlot, selectedAdSlots);

    if (shouldRender) {
      selectedAdSlots.push(adSlot);
    }
  }

  return selectedAdSlots;
}

function displayFrequencyComperator(adSlot: AdSlotFragment, otherAdSlot: AdSlotFragment) {
  const df1 = adSlot.displayFrequency || 'none';
  const df2 = otherAdSlot.displayFrequency || 'none';

  const priority1 = DISPLAY_FREQUENCY_PRIORITY[df1];
  const priority2 = DISPLAY_FREQUENCY_PRIORITY[df2];

  return priority1 - priority2;
}

// Display frequency testing functions
// -----------------------------------

/**
 * Test if the broser session just started.
 * @param _asSlot - adSlot to test (not in use with the function)
 * @returns true if the session just started
 */
function testGAMNewSession(_asSlot: AdSlotFragment): boolean {
  const gamSessionStarted = !!sessionStorage?.getItem(GAM_SESSION_KEY);

  if (!gamSessionStarted && sessionStorage) {
    sessionStorage.setItem(GAM_SESSION_KEY, 'started');
  }

  return !gamSessionStarted;
}

/**
 * Tests if the adSlot was not displayed with-in the last hour
 * @param adSlot - the ad-slot to test
 * @returns true if an hour has passed since last render
 */
function testOnceAnHour(adSlot: AdSlotFragment): boolean {
  const adSlotKey = `GAM_${adSlot.contentId}`;
  // for testing if the provided adSlot was viewed in the last hour.
  const adSlotLastViewValue = localStorage?.getItem(adSlotKey);
  let adSlotLastViewTime = null;

  // for testing if ANY out-of-page adSlot was rendered / view in the last hour.
  const outOfPageSlotLastRenderValue = localStorage?.getItem(GAM_OUTOFPAGE_KEY);
  let outOfPageSlotLastRenderTime = null;

  const now = Date.now();
  let result = false;

  // Parse last-view to millis
  if (adSlotLastViewValue) {
    try {
      adSlotLastViewTime = parseInt(adSlotLastViewValue, 10);
    } catch (e) {
      localStorage && localStorage.removeItem(adSlotKey);
    }
  }

  // parse last-render to millis
  if (outOfPageSlotLastRenderValue) {
    try {
      outOfPageSlotLastRenderTime = parseInt(outOfPageSlotLastRenderValue, 10);
    } catch (e) {
      localStorage && localStorage.removeItem(GAM_OUTOFPAGE_KEY);
    }
  }

  if (adSlotLastViewTime) {
    // test one hour
    result = now - adSlotLastViewTime >= ONE_HOUR;
  } else if (outOfPageSlotLastRenderTime) {
    // test 5 minute
    result = now - outOfPageSlotLastRenderTime >= HALF_MINUTE /* FIVE_MINUTES */;
  } else {
    // out-ot-page was never rendered. render it now
    result = true;
  }

  return result;
}

/**
 * Tests if no Out-of-page ad-slot was displayed with-in the last 5 seconds
 * @param _adSlot - ad-slot not in use with in the function.
 * @returns true if no out-of-page ad-slot was rendered with-in the last 5 seconds
 */
function testWhenPossible(_adSlot: AdSlotFragment, selectedAdSlots: AdSlotFragment[]): boolean {
  const isAnyOtherAdSlot = selectedAdSlots?.length > 0;
  let result = true;

  if (isAnyOtherAdSlot) {
    result = false;
  } else {
    const now = Date.now();
    const gamOutOfPageLastExposureValue = localStorage?.getItem(GAM_OUTOFPAGE_KEY);

    // Dont display banner if OutOfPage banner (interstitial) was displayed in the last 10 secons;
    if (gamOutOfPageLastExposureValue) {
      try {
        const gamOutOfPageLastExposureTime = parseInt(gamOutOfPageLastExposureValue, 10);
        result = now - gamOutOfPageLastExposureTime > TWENTY_SECONDS;
      } catch (e) {
        console.warn("unable to parse localStorage['GAM_OutOfPage'] to time.");
      }
    }
  }

  return result;
}

export default function AdSlotPrioritySelectorWrapper(props: AdSlotPrioritySelectorProps) {
  return (
    <ErrorBoundary>
      <AdSlotPrioritySelector {...props} />
    </ErrorBoundary>
  );
}
