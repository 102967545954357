import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import typesetter from '@haaretz/l-type.macro';
import { useMemo, useId } from 'react';
import s9 from 'style9';

import type { AdSlotFragment } from '@haaretz/s-fragments/AdSlot';

export interface AdSlotPlaceholderProps extends Omit<AdSlotFragment, 'inlineStyle'> {}

// Mapping between ad-units viewport to MQs
const viewport2Mq = {
  0: '',
  600: 's',
  768: 'm',
  1024: 'l',
  1280: 'xl',
  1920: 'xxl',
} as const;

const c = s9.create({
  base: {
    alignItems: 'center',
    justifyContent: 'center',
    marginInlineStart: 'auto',
    marginInlineEnd: 'auto',
    backgroundColor: color('sales500'),
    color: color('neutral100'),
    ...typesetter(1),
    minHeight: '1.5em',
    minWidth: '3em',
    width: 'var(--w)',
    height: 'var(--h)',
    '--name': 'var(--n)',
    display: 'var(--display, flex)',
    ':before': {
      content: 'var(--name)',
    },
    ...merge(
      mq({
        from: 's',
        until: 'm',
        value: {
          width: 'var(--sw, 0)',
          height: 'var(--sh, 0)',
          '--name': 'var(--sn,)',
          display: 'var(--sdisplay, flex)',
        },
      }),
      mq({
        from: 'm',
        until: 'l',
        value: {
          width: 'var(--mw, 0)',
          height: 'var(--mh, 0)',
          '--name': 'var(--mn,)',
          display: 'var(--mdisplay, flex)',
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          width: 'var(--lw, 0)',
          height: 'var(--lh, 0)',
          '--name': 'var(--ln,)',
          display: 'var(--ldisplay, flex)',
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: 'var(--xlw, 0)',
          height: 'var(--xlh, 0)',
          '--name': 'var(--xln,)',
          display: 'var(--xldisplay, flex)',
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: 'var(--xxlw, 0)',
          height: 'var(--xxlh, 0)',
          '--name': 'var(--xxln,)',
          display: 'var(--xxldisplay, flex)',
        },
      })
    ),
  },
  hidden: {
    display: 'none',
  },
});

export default function AdSlotPlaceholder(props: AdSlotPlaceholderProps) {
  const { interstitial, sizeMapping } = props;
  const divId = useId();

  const adSizes = useMemo(() => {
    const adSizesMap: Record<string, string> = {};

    sizeMapping?.forEach(sm => {
      const mqKey = sm.viewport[0] as keyof typeof viewport2Mq;
      const mqPrefix = viewport2Mq[mqKey];
      const adSize = sm.sizes.length > 0 ? sm.sizes[0] : null;

      if (!mqPrefix) {
        console.warn(`No matching media-query for ad-slot viewport (min-width): ${sm.viewport[0]}.
        current available mapping is:
          ${Object.keys(viewport2Mq)
            .map(vp => {
              const k = vp as unknown as keyof typeof viewport2Mq;
              return `${k} => ${viewport2Mq[k] || 'mobile'}`;
            })
            .join('\n\r')}`);

        return;
      }

      if (adSize) {
        adSizesMap[`--${mqPrefix}w`] = `${adSize[0]}px`;
        adSizesMap[`--${mqPrefix}h`] = `${adSize[1]}px`;
        adSizesMap[`--${mqPrefix}n`] = `'${adSize[0]}x${adSize[1]}'`;
      } else {
        adSizesMap[`--${mqPrefix}display`] = 'none';
      }
    });

    return adSizesMap;
  }, [sizeMapping]);

  return !interstitial ? (
    <div id={`${divId}`} className={s9(c.base)} style={adSizes}>
      &nbsp;
    </div>
  ) : null;
}
