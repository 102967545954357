import type { LiveBlogBreakingNewsFragment } from '@haaretz/s-fragments/LiveBlogBreakingNews';

const liveBlogBreakingNewsElementTypename: LiveBlogBreakingNewsFragment['__typename'] =
  'LiveBlogBreakingNews';

export default function isLiveBlogBreakingNewsElement<
  T extends Pick<LiveBlogBreakingNewsFragment, '__typename'>,
>(item?: T | { __typename: string } | null): item is LiveBlogBreakingNewsFragment {
  if (!item) return false;
  if (!(item instanceof Object)) return false;

  return item.__typename === liveBlogBreakingNewsElementTypename;
}
