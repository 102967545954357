'use client';

import config from '@haaretz/l-config';
import space from '@haaretz/l-space.macro';
import usePlatform from '@haaretz/s-atoms/platform';
import HtzLink from '@haaretz/s-htz-link';
import buildBaseImgSrc from '@haaretz/s-image-utils/buildBaseImgSrc';
import useAppUserAgent, {
  NativeAppUserAgentContextProvider,
} from '@haaretz/s-use-native-application/useAppUserAgent';
import * as React from 'react';
import s9 from 'style9';

import type { YoutubeFragment } from '@haaretz/s-fragments/embeds/Youtube.embeds';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    borderWidth: 0,
    aspectRatio: 'var(--video-aspect-ratio, 16/9)',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPositionY: 'center',
    position: 'relative',
  },
  appWrap: {
    position: 'relative',
  },
  appVideo: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    height: space(12),
    width: space(18),
    backgroundSize: 'contain',
  },
  vertical: {
    aspectRatio: '9/16',
  },
  wrapper: {
    width: '100%',
    aspectRatio: '16/9',
    position: 'relative',
  },
  relative: { position: 'relative' },
  hidden: { visibility: 'hidden' },
});

export interface YoutubeProps extends YoutubeFragment {
  styleExtend?: StyleExtend;
  children?: React.ReactNode;
}

const env = config.get('env');

function Youtube(props: YoutubeProps) {
  const {
    asGif,
    autoplay,
    controls,
    embedType,
    isVertical,
    logo,
    loop,
    related,
    startAt,
    videoImage,
    videoId,
    children,
  } = props;
  const appUserAgent = useAppUserAgent();

  const start = embedType === 'playlist' ? '&start=' : '?start=';

  // TODO: temporary disable fake youtube player on android, will be enabled after the fix of the youtube player
  const showFakePlayer = false && appUserAgent === 'android';

  let optionsString;

  const [showPlaceHolder, setShowPlaceHolder] = React.useState<boolean>(true);
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);

  if (asGif) {
    const chr = embedType === 'playlist' ? '&' : '?';
    optionsString = `${chr}controls=0&autoplay=1&loop=1&playlist=${videoId}&wmode=transparent&controls=0&modestbranding=1&rel=0&showinfo=0`;
  } else {
    const playlist = embedType !== 'playlist' && loop ? `&playlist=${videoId}` : ''; // must add playlist to enable looping
    optionsString = `${start}${startAt}&controls=${controls && '1'}&loop=${
      loop && '1'
    }&modestbranding=${logo && '1'}&rel=${
      related && '1'
    }&autoplay=${autoplay}&enablejsapi=1${playlist}&mute=${autoplay ? '1' : ''}`;
  }

  const prefixPlaylist = embedType === 'playlist' ? 'videoseries?list=' : '';
  const src = `https://www.youtube.com/embed/${prefixPlaylist}${videoId}${optionsString}`;

  React.useEffect(() => {
    const onLoad = () => {
      setShowPlaceHolder(false);
    };
    const iframe = iframeRef.current;
    if (iframe) {
      if (videoId) {
        iframe.src = src;
      }
      iframe.addEventListener('load', onLoad);

      return () => {
        iframe.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [src, videoId]);

  if (!videoId) {
    return null;
  }

  if (showFakePlayer) {
    const videoImageUrl = videoImage
      ? buildBaseImgSrc({
          imgData: videoImage.files[0],
          contentId: videoImage.contentId,
          isCropped: false,
          width: 599,
          env,
          aspect: 'headline',
        })
      : '';

    return (
      <div
        className={s9(c.base, c.appWrap)}
        style={{
          backgroundImage: `url(${videoImageUrl})`,
        }}
        data-testid="youtube-android-link"
      >
        <HtzLink
          href={`https://www.youtube.com/embed/${prefixPlaylist}${videoId}${start}${startAt}`}
        >
          <div
            className={s9(c.appVideo)}
            style={{
              backgroundImage:
                'url(https://img.haarets.co.il/bs/00000186-fee3-d31e-a9ef-ffe35b6e0001/98/8a/2e52eb9d4d449616480002c512b8/youtube-play-icon.png)',
            }}
          />
        </HtzLink>
      </div>
    );
  }

  return (
    <div className={s9(c.wrapper, isVertical && c.vertical)}>
      {showPlaceHolder && children}
      <iframe
        ref={iframeRef}
        className={s9(c.base, showPlaceHolder ? c.hidden : c.relative, isVertical && c.vertical)}
        allowFullScreen
        loading="lazy"
        title={`Youtube-${src}`}
      />
    </div>
  );
}

function YoutubePlaceholder({
  children,
  styleExtend = [],
}: Readonly<React.PropsWithChildren<Pick<YoutubeProps, 'styleExtend'>>>) {
  return <div className={s9(c.base, ...styleExtend)}>{children}</div>;
}

export default function YoutubeWrapper(props: Readonly<YoutubeProps>) {
  const platform = usePlatform();
  const isApp = platform === 'app';

  const Wrapper = isApp ? NativeAppUserAgentContextProvider : React.Fragment;

  return (
    <Wrapper
      fallback={
        <YoutubePlaceholder styleExtend={props.styleExtend}>{props.children}</YoutubePlaceholder>
      }
    >
      <Youtube {...props} />
    </Wrapper>
  );
}
