'use server';
import { getRenderingKind } from '@haaretz/s-server-context/renderingKind';
import getTopNews from '@haaretz/s-server-data-fetchers/getTopNews';

import TopNews from './TopNews.hybrid';

import type { BaseServerActionParams, PageType } from '@haaretz/s-types';

interface TopNewsProps extends BaseServerActionParams {
  pageType: PageType;
}
export default async function loadTopNews({ postCacheParams, pageType }: TopNewsProps) {
  const topNews = await getTopNews({ postCacheParams });

  const renderingKind = getRenderingKind();

  return <TopNews {...topNews.topNews} pageType={pageType} renderingKind={renderingKind} />;
}
